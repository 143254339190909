@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

/*Basic Layout*/

.flex-row-collapse {
  display: flex;
  flex-direction: row;
}

.flex-row-item {
  margin: 5px;
}

.flex-row-container-lg {
  width: 75%;
}

.flex-row-container-sm {
  margin-left: 15px;
  margin-top: 0px;
  width: 25%;
}

.flex-row-container-xs {
  width: 15%;
}

.nav-desktop {
  display: flex !important;
}

.nav-mobile {
  display: none !important;
}

.drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
}

.drawer-overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
}

.account-router {
  width: 10%;
  height: 50%;
  position: fixed;
  margin-top: 15vh;
}

@media only screen and (max-width: 600px) {
  .flex-row-collapse {
    display: flex;
    flex-direction: column;
  }

  .flex-row-container-lg {
    width: 100%;
  }

  .flex-row-container-sm {
    margin-left: 0px;
    margin-top: 15px;
    width: 100%;
  }

  .flex-row-container-xs {
    width: 100%;
  }

  .nav-desktop {
    display: none !important;
  }

  .nav-mobile {
    display: flex !important;
  }

  .drawer {
    display: flex;
    justify-content: center;
    width: 70%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
  }

  .account-router {
    width: 100%;
    position: relative;
    margin-top: 35px;
  }
}

/*Color*/

.light {
  color: white !important;
}

.dark {
  color: black !important;
}

.dark-theme {
  background: #20262f;
}

.light-theme {
  background: white;
}

.dark-theme .background {
  background: #20262f;
}

.light-theme .background {
  background: white;
}

.dark-theme .divider-color {
  background: white;
}

.light-theme .divider-color {
  background: black;
}

.dark-theme .footer-border {
  border-top: 1px solid white;
}

.light-theme .footer-border {
  border-top: 1px solid black;
}

.dark-theme .text-color {
  color: white;
}

.light-theme .text-color {
  color: black;
}

/*Text*/

.header-text {
  font-size: 48px;
  font-family: "Playfair Display", serif;
  word-wrap: break-word;
}

.main-text {
  font-size: 20px;
  font-family: "Playfair Display", serif;
  word-wrap: break-word;
}

.sub-text {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  word-wrap: break-word;
}

.special-text {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
}

.special-text-red {
  color: #fa3c3c !important;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
}

.main-description {
  font-size: 14px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  word-wrap: break-word;
}

.sub-description {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  word-wrap: break-word;
}

.text-box {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.text-box-scenic {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20px;
}

.dark-theme .header-text {
  color: white !important;
  font-size: 48px;
  font-family: "Playfair Display", serif;
  word-wrap: break-word;
}

.dark-theme .main-text {
  color: white !important;
  font-size: 20px;
  font-family: "Playfair Display", serif;
  word-wrap: break-word;
}

.dark-theme .sub-text {
  color: white !important;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  word-wrap: break-word;
}

.dark-theme .special-text {
  color: white !important;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: bold;
}

.dark-theme .main-description {
  color: white !important;
  font-size: 14px;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  word-wrap: break-word;
}

.dark-theme .sub-description {
  color: white !important;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  word-wrap: break-word;
}

.dark-theme .text-box {
  color: white !important;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.dark-theme .text-box-scenic {
  color: white !important;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 20px;
}

@media only screen and (max-width: 600px) {
  .header-text {
    font-size: 32px !important;
    font-family: "Playfair Display", serif;
    word-wrap: break-word;
  }

  .main-text {
    font-size: 16px !important;
    font-family: "Playfair Display", serif;
    word-wrap: break-word;
  }

  .sub-text {
    font-size: 14px !important;
    font-family: "Montserrat", sans-serif;
    word-wrap: break-word;
  }

  .special-text-red {
    color: #fa3c3c !important;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: bold;
  }

  .main-description {
    font-size: 12px !important;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    word-wrap: break-word;
  }

  .sub-description {
    font-size: 12px !important;
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    word-wrap: break-word;
  }

  .text-box {
    display: flex !important;
    flex-direction: column;
    padding-left: 10px;
  }

  .text-box-scenic {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
  }
}

/*Artwork Card*/

.outer-card {
  padding: 15px;
}

.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.card-scenic {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.scaler {
  transform: scale(1);
}

.scaler:hover {
  transform: scale(1.008);
}

.dark-theme .hover-icon {
  color: rgba(0, 0, 0, 0.8);
}

.light-theme .hover-icon {
  color: rgba(255, 255, 255, 0.8);
}

/*Artwork Detail*/

.artwork-display {
  display: flex;
  flex-direction: row;
  margin: 15px;
}

.artwork-display-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4%;
  width: 65%;
}

.light-theme .artwork-display-image {
  background: #f7f7f7;
}

.dark-theme .artwork-display-image {
  background: #393e46;
}

.artwork-display-text {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 20px;
}

.stat-icon {
  font-size: 24px;
}

@media only screen and (max-width: 1400px) {
  .artwork-display {
    flex-direction: column;
  }

  .artwork-display-image {
    width: 100%;
  }

  .artwork-display-text {
    width: 100%;
    padding: 0px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .stat-icon {
    font-size: 18px;
  }
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 20px;
  width: 100%;
}

.icon-button {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 35px;
  width: 150px;
  height: 35px;
  margin-bottom: 10px;
  background: white;
}

.icon-text {
  white-space: nowrap;
}

.image {
  max-width: 100%;
  max-height: 850px;
}

.ant-card-body {
  padding: 0px !important;
}

.ant-tag {
  margin-left: 5px !important;
}

.ant-form-item-control-input {
  min-height: 0 !important;
}

.artwork-detail {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 5px;
  padding: 5px;
}

.light-theme .artwork-detail {
  background: #f4f7fa;
}

.dark-theme .artwork-detail {
  background: #393e46;
}

.grid-sizer {
  width: 20%;
}

@media only screen and (max-width: 2000px) {
  .grid-sizer {
    width: 25%;
  }
}

@media only screen and (max-width: 1600px) {
  .grid-sizer {
    width: 33.33%;
  }
}

@media only screen and (max-width: 1000px) {
  .grid-sizer {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .grid-sizer {
    width: 100%;
  }
}
